export default [
  {
    title: 'Module Packages',
    icon: 'PackageIcon',
    route: 'ManagePakages',
    acl: {
      roles: ['super'],
    },
    // children: [
    //   {
    //     title: 'eCommerce',
    //     route: 'dashboard-ecommerce',
    //   },
    //   // {
    //   //   title: 'Analytics',
    //   //   route: 'dashboard-analytics',
    //   // },
    // ],
  },
]
